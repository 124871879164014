import { FC, useCallback, useEffect, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { _ } from "common";
import modules from "modules";
import { useAppDispatch } from "hooks/use-app-dispatch";
import { useAppSelector } from "hooks/use-app-selector";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/css";
import SwiperCarousel from "components/swiper/SwiperCarousel";
import NewProductCard from "components/product-cards/NewProductCard";
import { useIsMobile } from "hooks";
import { useCheckPath } from "hooks/use-check-path";

const { actions: productsActions, selectors: productsSelectors } =
  modules.products;

const breakpointsGeneral = {
  0: { slidesPerView: 1.25 },
  600: { slidesPerView: 2.25 },
  900: { slidesPerView: 3.25 },
  1200: { slidesPerView: 5.25 },
  1536: { slidesPerView: 5.25 },
};

const breakpointsNoResultPage = {
  0: { slidesPerView: 1.25 },
  600: { slidesPerView: 2.25 },
  900: { slidesPerView: 2.25 },
  1200: { slidesPerView: 3.25 },
  1536: { slidesPerView: 3.25 },
};

type Props = { isLarge?: boolean };

const BestSellerSection: FC<Props> = ({ isLarge = false }) => {
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();

  const isProductsPage = useCheckPath("/product/search/[id]");

  const bestSellingProducts = useAppSelector(
    productsSelectors.selectBestSellingProducts
  );
  const bestSellingLoading = useAppSelector(
    productsSelectors.selectBestSellingLoading
  );

  const getBestSellingProducts = useCallback(() => {
    dispatch(productsActions.getBestSellingProductsThunk());
  }, [dispatch]);

  useEffect(() => {
    getBestSellingProducts();
  }, [getBestSellingProducts]);

  return (
    <Box
      flex="1 1 auto"
      minWidth="0px"
      mt={4}
      p={3}
      pt={1}
      sx={{
        background: "#2F80ED",
        borderRadius: "20px",
      }}
    >
      <Box width={"100%"} sx={{ display: "grid", placeItems: "center" }}>
        <Typography
          fontSize={isMobile ? "32px" : "36px"}
          fontWeight={700}
          color="white"
          sx={{
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
          }}
        >
          Best Sellers
        </Typography>
      </Box>
      <SwiperCarousel
        id="products"
        data={bestSellingProducts}
        renderItem={(item) => <NewProductCard hoverEffect productData={item} />}
        isLoading={bestSellingLoading}
        breakpoints={
          isProductsPage ? breakpointsNoResultPage : breakpointsGeneral
        }
      />
    </Box>
  );
};

export default BestSellerSection;

import { Box, Grid, Hidden } from "@mui/material";
import { _ } from "common";
import CategorySectionHeader from "components/CategorySectionHeader";
import { ProductsSkeleton } from "components/skeletons";
import { FC, useEffect } from "react";
import GalleryBuildItem from "components/gallery/GalleryBuildItem";
import { useBuildsGallery } from "hooks/use-builds-gallery";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/css/controller";
import "swiper/css";
import "swiper/css/navigation";
import SwiperCarousel from "components/swiper/SwiperCarousel";
import { useCheckPath } from "hooks/use-check-path";

const LatestBuildsSection: FC = () => {
  const isProductsPage = useCheckPath("/product/search/[id]");
  const {
    getBuildsGalleryRequest,
    isBuildsGalleryLoading,
    buildsGalleryItems,
  } = useBuildsGallery();

  const breakpoints = {
    0: { slidesPerView: 2.25 },
    600: { slidesPerView: 3.25 },
    900: { slidesPerView: 4.25 },
    1200: { slidesPerView: 5.25 },
    1536: { slidesPerView: 6.25 },
  };

  const breakpointsNoResultPage = {
    0: { slidesPerView: 1.5 },
    600: { slidesPerView: 1.5 },
    900: { slidesPerView: 4.25 },
    1200: { slidesPerView: 4.25 },
    1536: { slidesPerView: 4.25 },
  };

  const getLatestBuilds = () => {
    getBuildsGalleryRequest();
  };

  useEffect(() => {
    getLatestBuilds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box flex="1 1 auto" minWidth="0px" mt={4}>
      <CategorySectionHeader
        title="Latest Builds"
        seeMoreLinkTxt="See All"
        seeMoreLink="/builds-gallery"
      />
      <SwiperCarousel
        id="latestBuilds"
        data={buildsGalleryItems}
        renderItem={(item) => <GalleryBuildItem data={item} />}
        isLoading={isBuildsGalleryLoading}
        breakpoints={isProductsPage ? breakpointsNoResultPage : breakpoints}
      />
    </Box>
  );
};

export default LatestBuildsSection;

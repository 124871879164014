import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Skeleton from "@mui/material/Skeleton";
import { Box } from "@mui/material";
import BazarCard from "components/BazarCard";
import styled from "@emotion/styled";

interface Props {}

const StyledBazarCard = styled(BazarCard)(() => ({
  height: "100%",
  minHeight: 400,
  margin: "auto",
  display: "flex",
  overflow: "hidden",
  borderRadius: "8px",
  position: "relative",
  flexDirection: "column",
  justifyContent: "space-between",
  transition: "all 250ms ease-in-out",
}));

export const ProductsSkeleton = (props: Props) => {
  return (
    <StyledBazarCard>
      <Skeleton
        sx={{ flex: 1 }}
        height={100}
        animation="wave"
        variant="rectangular"
      />

      <CardContent>
        <Box mb={2}>
          <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
          <Skeleton animation="wave" height={10} width="80%" />
        </Box>
        <Box mb={2}>
          <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
          <Skeleton animation="wave" height={10} width="80%" />
        </Box>
      </CardContent>
    </StyledBazarCard>
  );
};

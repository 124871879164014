import { useCallback } from "react";

import modules from "modules";
import { useAppSelector } from "./use-app-selector";
import { useAppDispatch } from "./use-app-dispatch";

const { selectors: buildsGallerySelectors, actions: buildsGalleryActions } =
  modules.buildsGallery;

export const useBuildsGallery = () => {
  const dispatch = useAppDispatch();

  /** ========== SELECTORS / VARIABLES / CONSTANTS ========== */
  const buildsGalleryItems = useAppSelector(
    buildsGallerySelectors.selectBuildsGallery
  );
  const isBuildsGalleryLoading = useAppSelector(
    buildsGallerySelectors.selectBuildsGalleryLoading
  );

  const buildsItemOnProduct = useAppSelector(
    buildsGallerySelectors.selectBuildsItemsOnProduct
  );

  const buildsSearchProductName = useAppSelector(
    buildsGallerySelectors.selectBuildsProductSearchName
  );

  /** ========== FUNCTIONS ========== */
  const getBuildsGalleryRequestThunk = useCallback(() => {
    dispatch(buildsGalleryActions.getBuildsGalleryThunk({ limit: 12 }));
  }, [dispatch]);

  const getBuildsGalleryRequest = useCallback(() => {
    dispatch(buildsGalleryActions.getBuildsGalleryThunk({ limit: 12 }));
  }, [dispatch]);

  const getBuildsGalleryOnProducts = useCallback(
    (uuid: string) => {
      dispatch(buildsGalleryActions.getBuildsItemOnProductThunk(uuid));
    },
    [dispatch]
  );

  const getBuildsGalleryProductName = useCallback(
    (prodName: string) => {
      dispatch(buildsGalleryActions.buildsProductSearch({ prodName }));
    },
    [dispatch]
  );

  return {
    buildsGalleryItems,
    isBuildsGalleryLoading,
    buildsItemOnProduct,
    buildsSearchProductName,

    getBuildsGalleryRequest,
    getBuildsGalleryRequestThunk,
    getBuildsGalleryOnProducts,
    getBuildsGalleryProductName,
  };
};

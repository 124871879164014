import { Button, Grid } from "@mui/material";
import { _, colors } from "common";
import { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/css/controller";
import "swiper/css";
import "swiper/css/navigation";
import { ProductsSkeleton } from "components/skeletons";
import { Navigation, SwiperOptions } from "swiper";
import { makeStyles } from "@mui/styles";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import useWindowSize from "hooks/useWindowSize";

const useStyles = makeStyles({
  navBtn: {
    border: `1px solid #D8E0E9`,
    borderRadius: "50%",
    position: "absolute",
    zIndex: 5,
    backgroundColor: "white",
    color: colors.primary,
    "&:hover": {
      backgroundColor: colors.primary,
      color: "white",
    },
  },
  disabled: {
    opacity: 0,
    pointerEvents: "none",
  },
});

/* Coded this way to allow for generic type usage.
 *  See TypeScript Generics with Functional React Components documentation for more info. */
interface SwiperCarouselProps<T> {
  id: string;
  data?: T[];
  renderItem: (item: T) => JSX.Element;
  isLoading?: boolean;
  breakpoints?: {
    [width: number]: SwiperOptions;
    [ratio: string]: SwiperOptions;
  };
}

// Coded this way to allow for generic type usage
function SwiperCarousel<T>({
  id,
  data,
  renderItem,
  isLoading,
  breakpoints,
}: SwiperCarouselProps<T>) {
  const classes = useStyles();
  const width = useWindowSize();
  const swiperRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(0);

  const [nextEl, prevEl] = [`${id}_swiper_next`, `${id}_swiper_prev`];

  const isMobile = width <= 900;

  // Disable specific navButtons when swiper is at either end points.
  const handleSwiperStart = () => {
    document.querySelector(`.${prevEl}`)?.classList.add(classes.disabled);
  };

  const handleSwiperEnd = () => {
    document.querySelector(`.${nextEl}`)?.classList.add(classes.disabled);
  };

  const handleFromEdge = () => {
    document.querySelector(`.${prevEl}`)?.classList.remove(classes.disabled);
    document.querySelector(`.${nextEl}`)?.classList.remove(classes.disabled);
  };

  const resizeObserver = new ResizeObserver((entries) => {
    for (let entry of entries) {
      setContainerHeight(entry.contentRect.height);
    }
    setTimeout(handleSwiperStart, 100); // Small delay to let mounting finish
  });

  useEffect(() => {
    if (swiperRef.current) {
      resizeObserver.observe(swiperRef.current); // Monitor height updates for proper nav buttons positioning
    }

    // Cleanup function to disconnect the ResizeObserver when the component unmounts
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (swiperRef.current) {
        resizeObserver.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Swiper
      ref={swiperRef}
      spaceBetween={5}
      onReachBeginning={handleSwiperStart}
      onReachEnd={handleSwiperEnd}
      onFromEdge={handleFromEdge}
      breakpoints={breakpoints}
      modules={[Navigation]}
      navigation={{
        nextEl: `.${nextEl}`,
        prevEl: `.${prevEl}`,
      }}
      style={{ padding: 7 }}
    >
      <Grid container spacing={1}>
        {_.map(data, (item, index) => (
          <SwiperSlide key={index} style={{ height: "auto" }}>
            <Grid item py={1} sx={{ height: "100%" }}>
              {renderItem(item)}
            </Grid>
          </SwiperSlide>
        ))}
        {isLoading
          ? _.map(data, (_, ind) => {
              <SwiperSlide>
                <Grid item md={2} sm={4} xs={6} key={ind}>
                  <ProductsSkeleton />
                </Grid>
              </SwiperSlide>;
            })
          : null}
      </Grid>
      <Button
        className={`${nextEl} ${classes.navBtn}`}
        sx={{
          top: containerHeight / 2.3,
          height: isMobile ? "initial" : "4em",
          width: isMobile ? "initial" : "4em",
          right: 0,
        }}
      >
        <NavigateNextIcon sx={{ fontSize: { xs: "16px", md: "24px" } }} />
      </Button>
      <Button
        className={`${prevEl} ${classes.navBtn}`}
        sx={{
          top: containerHeight / 2.3,
          height: isMobile ? "initial" : "4em",
          width: isMobile ? "initial" : "4em",
          left: 0,
        }}
      >
        <NavigateBeforeIcon sx={{ fontSize: { xs: "16px", md: "24px" } }} />
      </Button>
    </Swiper>
  );
}

export default SwiperCarousel;

import { Box, SxProps, Theme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { colors } from "common";
import React, { useMemo } from "react";
import { getRandomInt } from "utils/number/get-random-int";

const sxStyles: { [key: string]: SxProps<Theme> } = {
  root: {
    borderRadius: 1,
    alignItems: "center",
    justifyContent: "center",
    pl: 0.5,
    mt: 0.5,
    mr: 0.5,
    pr: 0.5,
  },
  title: {
    fontSize: 12,
  },
};

const GalleryBuildTags = ({ title }) => {
  const getRandomMuiColor = () => {
    const randomInt = getRandomInt(0, 19);
    switch (randomInt) {
      case 0:
        return colors.amber;
      case 1:
        return colors.blue;
      case 2:
        return colors.blueGrey;
      case 3:
        return colors.brown;
      // case 4:
      //   return colors.common;
      case 5:
        return colors.cyan;
      case 6:
        return colors.deepOrange;
      case 7:
        return colors.deepPurple;
      case 8:
        return colors.green;
      case 9:
        return colors.grey;
      case 10:
        return colors.indigo;
      case 11:
        return colors.lightBlue;
      case 12:
        return colors.lightGreen;
      case 13:
        return colors.lime;
      case 14:
        return colors.orange;
      case 15:
        return colors.pink;
      case 16:
        return colors.purple;
      case 17:
        return colors.red;
      case 18:
        return colors.teal;
      case 19:
        return colors.yellow;
      default:
        return colors.green;
    }
  };

  const getBuildGalleryTagColor = useMemo(() => {
    switch (title) {
      case "Streaming":
        return colors.orange;
      case "Gaming":
        return colors.red;
      case "Video Editing":
        return colors.yellow;
      case "Mid":
        return colors.blue;
      case "Entry":
        return colors.green;
      default:
        return getRandomMuiColor();
    }
  }, [title]);

  const tagsBgColor = useMemo(() => {
    return getBuildGalleryTagColor;
  }, [getBuildGalleryTagColor]);

  return (
    <Box sx={sxStyles.root} style={{ backgroundColor: tagsBgColor[200] }}>
      <Typography sx={sxStyles.title} style={{ color: tagsBgColor[800] }}>
        {title}
      </Typography>
    </Box>
  );
};

export default GalleryBuildTags;

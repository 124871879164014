import React from "react";
import Typography from "@mui/material/Typography";

import { colors, formatCurrency } from "common";
import { Box, SxProps, Theme } from "@mui/material";

const sxStyles: { [key: string]: SxProps<Theme> } = {
  root: {
    mt: 1,
    height: 40,
    overflow: "hidden",
  },
  origPriceRow: {
    display: "flex",
    flexDirection: "row",
  },
  promoPriceTxt: {
    color: colors.primary,
    fontWeight: 600,
    fontSize: 18,
  },
  origPriceTxt: {
    color: colors.warning,
    fontWeight: 400,
    fontSize: 12,
    textDecoration: "line-through",
  },
  percentageTxt: {
    ml: 0.5,
    color: colors.success,
    fontWeight: 600,
    fontSize: 12,
  },
  papansinPrice: {
    color: colors.grey[400],
    fontWeight: 400,
    fontSize: 12,
  },
};

export const BuildsGalleryPrice = ({ data }) => {
  return (
    <Box sx={sxStyles.root}>
      {data && data.have_promo ? (
        <Box>
          <Typography sx={sxStyles.promoPriceTxt}>
            {formatCurrency(data && data.amount ? data.amount : 0 || 0)}
          </Typography>
          <Box sx={sxStyles.origPriceRow}>
            <Typography sx={sxStyles.origPriceTxt}>
              {formatCurrency(
                data && data.orig_amount ? data.orig_amount : 0 || 0
              )}
            </Typography>
            {data && data.discount_percentage ? (
              <Typography sx={sxStyles.percentageTxt}>
                {`-${data.discount_percentage}`}
              </Typography>
            ) : null}
          </Box>
        </Box>
      ) : (
        <Box>
          <Typography sx={sxStyles.promoPriceTxt}>
            {formatCurrency(data && data.amount ? data.amount : 0 || 0)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

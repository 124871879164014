import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { colors } from "common";
import BazarImage from "components/BazarImage";
import { H6, Tiny } from "components/Typography";
import { BuildsGalleryItem } from "modules/builds-gallery/types";
import Link from "next/link";
import { FC, useMemo, useState } from "react";
import { BuildsGalleryPrice } from "./GalleryBuildPrice";
import GalleryBuildTags from "./GalleryBuildTags";

// ================================================================
interface Props {
  data: BuildsGalleryItem;
}
// ================================================================

const placeHolderTags = ["PC", "WORTH", "LANG", "SAKALAM"];

const GalleryBuildItem: FC<Props> = ({ data }) => {
  const [badgeImgErr, setBadgeImgErr] = useState("");
  const [coverImgErr, setCoverImgErr] = useState("");

  const bgColorOFCpuContainer = useMemo(() => {
    if (data) {
      if (data.cpu === "Intel") {
        return "#0068B5";
      }
      if (data.cpu === "AMD") {
        return "#FC5C00";
      }
    }
    return colors.primary;
  }, [data]);

  const buildTags = useMemo(() => {
    if (data && data.build_tags && data.build_tags.length > 0) {
      return data.build_tags;
    }
    return placeHolderTags;
  }, [data]);

  return (
    <Card elevation={1}>
      <CardActionArea href={`/builds-gallery/details/${data?.uuid}`}>
        <Box sx={{ position: "relative" }}>
          <CardMedia
            component="img"
            height="180"
            image={
              data?.cover_thumbnail ||
              data?.cover ||
              coverImgErr ||
              "/static/images/logo.png" ||
              ""
            }
            alt={data?.title || "cover"}
            onError={() => setCoverImgErr("/static/images/logo.png")}
          />
          {data && data?.cpu ? (
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: 80,
                borderTopRightRadius: 3,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid white",
                backgroundColor: bgColorOFCpuContainer,
              }}
            >
              <H6 color="white">{data?.cpu || ""}</H6>
            </Box>
          ) : null}
        </Box>

        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {data && data?.badge ? (
              <BazarImage
                alt="badge"
                sx={{
                  width: undefined,
                  height: 20,
                  mr: 1,
                }}
                src={badgeImgErr || data?.badge || "/static/images/logo.png"}
                onError={() => setBadgeImgErr("/static/images/logo.png")}
              />
            ) : null}
            <H6
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
              }}
            >
              {data?.title || ""}
            </H6>
          </Box>
          <Tiny color="text.secondary">{data?.created_date || ""}</Tiny>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginTop: 10,
              height: 44,
              justifyContent: "flex-start",
              alignItems: "flex-start",
              overflow: "hidden",
            }}
          >
            {/* eslint-disable-next-line react/no-array-index-key */}
            {buildTags?.map((tag, i) => (
              <GalleryBuildTags key={`${i}${tag}`} title={tag} />
            ))}
          </div>
          <BuildsGalleryPrice data={data} />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default GalleryBuildItem;
